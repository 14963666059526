<template>
  <div>
    <div
      v-if="!isLoaded"
      class="text-center p-5"
    >
      <b-spinner />
    </div>
    <template v-else>
      <div v-if="!downloads.length">
        <div class="items-empty-message-container">
          <div class="mb-1">
            <send-icon />
          </div>
          <b-card-text class="list-empty-text-color">
            There are no downloads yet.
          </b-card-text>
        </div>
      </div>
      <div v-else>
        <b-card
          no-body
          class="mb-0"
        >
          <div class="dashboard-title p-2">
            Downloads
          </div>
          <b-table
            v-if="isLoaded"
            ref="refshipmentListTable"
            class="position-relative"
            :per-page="queryParams.limit"
            :items="downloads"
            responsive
            :fields="tableColumns"
            primary-key="_id"
            show-empty
          >
            <template #cell(createdAt)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  getDateFromTimestamp(data.item.createdAt)
                }} {{ getFromattedTimeFromDate(data.item.createdAt) }}
                </span>
              </div>
            </template>
            <template #cell(downloadType)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  getDownloadTypeName(data.item.downloadType)
                }}</span>
              </div>
            </template>
            <template #cell(status)="data">
              <b-badge
                pill
                :class="getOrderStatusCssClass(data.item.status)"
                class="text-capitalize table-status color-black"
              >
                {{ getDownloadStatus(data.item.status) }}
              </b-badge>
            </template>
            <template #cell(downloadUrl)="data">
              <div
                v-if="data.item.downloadUrl"
                class="d-flex color-blue align-items-center"
              >
                <div
                  class="download-link mr-50 cursor-pointer"
                  @click="download(data.item.downloadUrl)"
                >
                  Click here
                </div>
                <feather-icon
                  icon="CopyIcon"
                  size="18"
                  class="cursor-pointer"
                  @click="copyDownloadLink(data.item.downloadUrl)"
                />
              </div>
            </template>
          </b-table>
        </b-card>
        <div
          v-if="hasPagination"
          class="my-2"
        >
          <b-row>
            <!-- Pagination -->
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-center"
            >
              <b-pagination
                v-model="queryParams.page"
                :total-rows="totaldownloads"
                :per-page="queryParams.limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0 page-link-class"
                prev-class="prev-item"
                next-class="next-item"
                @input="getDownloadsList"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BPagination,
  BSpinner,
  BCard,
  BTable,
  BBadge,
  BCardText,
} from 'bootstrap-vue'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import SendIcon from '@/@core/assets/svg-components/SendIcon.vue'
import { getDateFromTimestamp, getFromattedTimeFromDate } from '@/common-utils'
import { download, getDownloadTypeName } from '@/@core/utils/utils'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { GET_DOWNLOADS_LIST } from '@/store/modules/common.module'
import { DOWNLOAD_FILE_STATUS } from '@/constants'
import { RESET_DOWNLOADS_NAV_INDICATOR } from '@/store/modules/notification.module'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
    BSpinner,
    BCard,
    BTable,
    SendIcon,
    FeatherIcon,
    BBadge,
    BCardText,
  },
  data() {
    return {
      downloads: [],
      isLoaded: false,
      queryParams: {
        page: 1,
        limit: 10,
      },
      tableColumns: [
        {
          key: 'respectiveName',
          label: 'Name',
        },
        {
          key: 'downloadType',
          label: 'Type',
        },
        {
          key: 'createdAt',
          label: 'Date',
        },
        {
          key: 'fileType',
          label: 'File Type',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'downloadUrl',
          label: 'Download Link',
        },
      ],
      totaldownloads: 0,
      hasPagination: true,
      getDateFromTimestamp,
      download,
      getFromattedTimeFromDate,
      getDownloadTypeName,
    }
  },
  mounted() {
    this.$store.commit(RESET_DOWNLOADS_NAV_INDICATOR)
    this.getDownloadsList()
  },
  methods: {
    async getDownloadsList() {
      try {
        const params = {
          ...this.queryParams,
          page: this.queryParams.page - 1,
        }
        const result = await this.$store.dispatch(GET_DOWNLOADS_LIST, params)
        this.downloads = result.data.data.downloads
        this.totaldownloads = result.data.data.count
      } catch (err) {
        apiToastWarning(err)
      } finally {
        this.isLoaded = true
      }
    },
    getOrderStatusCssClass(status) {
      if (status === DOWNLOAD_FILE_STATUS.ERROR.VALUE) return 'rejected'
      if (status === DOWNLOAD_FILE_STATUS.READY_TO_DOWNLOAD.VALUE) return 'confirmed'
      return 'pending'
    },
    getDownloadStatus(status) {
      return Object.values(DOWNLOAD_FILE_STATUS).find(_ => _.VALUE === status)?.LABEL
    },
    copyDownloadLink(link) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          apiToastSuccess('Download Link Copied')
        })
        .catch(err => {
          apiToastWarning(err)
        })
    },
  },
}
</script>

<style lang="scss" >
.dashboard-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}
</style>
